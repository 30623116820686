import React from 'react';

import './hero.scss';

export default (): JSX.Element => (
    <div className="hero">
        <div className="hero__name">Unen Tsogtbaatar &nbsp;</div>
        <div className="hero__title">Frontend Developer</div>
    </div>
);
