import React from 'react';
import Hero from '../components/hero/Hero';
import Layout from '../components/layout/Layout';
import '../styles/styles.scss';

export default (): JSX.Element => {
    return (
        <Layout>
            <Hero />
        </Layout>
    );
};
